import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'

import {
  fieldsToInitialise,
  groupedDataField,
  GroupedFormFields,
  ReferenceDataRuleFormData,
} from '@lib/pgs-types'
import { trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { ReferenceDataRuleForm } from './ReferenceDataRuleForm'

const Form = getFormComponents<ReferenceDataRuleFormData>()

export function ReferenceDataRuleCreatePage() {
  const form = Form.useForm({
    defaultValues: initialiseDefaults(),
  })

  const navigate = useNavigate()
  const notify = usePromiseNotification()
  const createMutation = trpc.reference.referenceDataRule.create.useMutation({
    onSuccess(data) {
      navigate({
        to: '/reference-data/rule/edit/$id',
        params: { id: data.id },
      })
    },
  })
  const utils = useUtils()

  const handleSubmit = form.handleSubmit(async (values) => {
    await notify(createMutation.mutateAsync(values), {
      progressMessage: `Creating Reference Data Rule`,
      successMessage: `Created Reference Data Rule`,
      failureMessage: `Failed to create Reference Data Rule`,
    })

    utils.reference.referenceDataRule.invalidate()
  })

  return (
    <Form.Provider {...form}>
      <Form.FormStack onSubmit={handleSubmit} submitOnCtrlEnter>
        <Form.SectionTitleRow title="Create Record Reference Data Entry Rule">
          <Button
            startIcon={<Icons.Save />}
            type="submit"
            variant="contained"
            disabled={createMutation.isPending || createMutation.isSuccess}
          >
            Create
          </Button>
        </Form.SectionTitleRow>

        <ReferenceDataRuleForm />
      </Form.FormStack>
    </Form.Provider>
  )
}

function initialiseDefaults() {
  const fields = fieldsToInitialise.reduce((acc, field) => {
    acc[field] = 'OPTIONAL'
    return acc
  }, {} as Partial<ReferenceDataRuleFormData>)

  const groupedFields = groupedDataField.reduce((acc, field) => {
    acc[field] = 0

    return acc
  }, {} as GroupedFormFields)

  return {
    ...fields,
    ...groupedFields,
    assetTypes: [],
  } as ReferenceDataRuleFormData
}
