import * as Icons from '@mui/icons-material'

import { ButtonLink, When } from '@tk/frontend/primitives'

export function ReferenceDataRuleCreateButton() {
  return (
    <When can="reference-data-rules.manage">
      <ButtonLink
        startIcon={<Icons.Add />}
        variant="contained"
        to="/reference-data/rule/create"
      >
        Create
      </ButtonLink>
    </When>
  )
}
