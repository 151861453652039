import { Box, TextField } from '@mui/material'
import { DataGridPremium } from '@mui/x-data-grid-premium'

import { trpc } from '@tk/frontend/api'
import { defaultProps } from '@tk/frontend/primitives/datagrid'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'

import { useColumns } from './useColumns'

export function ReferenceDataRuleCrudTable() {
  const [list] = trpc.reference.referenceDataRule.list.useSuspenseQuery()
  const cols = useColumns()

  const { filteredList, filterValue, setFilterValue } = useClientFilter(list)

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%ICAP%"'
        fullWidth
      />

      <Box flex="1 1 0" minHeight={0} minWidth={0}>
        <DataGridPremium
          {...defaultProps}
          rows={filteredList}
          columns={cols}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
            density: 'compact',
          }}
        />
      </Box>
    </Box>
  )
}
