import {
  clearingTypeMode,
  NamedBusinessObjectDto,
  ReferenceDataRuleRequestDto,
} from '@lib/pgs'

export const groupedDataField = [
  'currencies',
  'day-counts',
  'payment-frequencies',
  'periods',
  'reset-frequencies',
  'settlement-indexes',
] as const

export type FieldEntryMode = clearingTypeMode

export type GroupedDataField = (typeof groupedDataField)[number]

type FieldEntryModeKeys<T> = {
  [K in keyof Required<T>]: T[K] extends FieldEntryMode ? K : never
}[keyof T]

export const fieldsToInitialise: FieldEntryModeKeys<ReferenceDataRuleRequestDto>[] =
  [
    'clearingTypeMode',
    'companyMode',
    'currency1Mode',
    'currency2Mode',
    'dayCount1Mode',
    'dayCount2Mode',
    'locationMode',
    'paymentFrequency1Mode',
    'paymentFrequency2Mode',
    'period1Mode',
    'period2Mode',
    'period3Mode',
    'period4Mode',
    'priceTypeMode',
    'pricingFrequencyMode',
    'resetFrequency1Mode',
    'resetFrequency2Mode',
    'settlementIndex1Mode',
    'settlementIndex2Mode',
    'settlementMethodMode',
    'standardDiscountingMode',
  ] as const

export type InitialisedField = (typeof fieldsToInitialise)[number]

export const groupedFields: Record<GroupedDataField, InitialisedField[]> = {
  'currencies': ['currency1Mode', 'currency2Mode'],
  'day-counts': ['dayCount1Mode', 'dayCount2Mode'],
  'payment-frequencies': ['paymentFrequency1Mode', 'paymentFrequency2Mode'],
  'periods': ['period1Mode', 'period2Mode', 'period3Mode', 'period4Mode'],
  'reset-frequencies': ['resetFrequency1Mode', 'resetFrequency2Mode'],
  'settlement-indexes': ['settlementIndex1Mode', 'settlementIndex2Mode'],
} as const

export type GroupedFormFields = Record<GroupedDataField, number>

export type ReferenceDataRuleFormData = ReferenceDataRuleRequestDto &
  GroupedFormFields &
  NamedBusinessObjectDto
