import { createFileRoute } from '@tanstack/react-router'

import { RouterWhen } from '@tk/frontend/primitives'

export const Route = createFileRoute('/record-management')({
  component: Surface,
})

function Surface() {
  return <RouterWhen can="records.read" />
}
