import * as Icons from '@mui/icons-material'
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded'
import { useNavigate } from '@tanstack/react-router'

import { When } from '@tk/frontend/primitives'
import { MenuButton, MenuItem } from '@tk/frontend/primitives/menu'

export function RecordDesignerMenu() {
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate({ to: '/record-design/start' })
  }

  return (
    <When can="record-projects.read">
      <MenuButton label="Record Projects" icon={<BusinessCenterRoundedIcon />}>
        <MenuItem startIcon={<Icons.Add />} onClick={handleNavigate}>
          Open Project
        </MenuItem>
      </MenuButton>
    </When>
  )
}
