import { useFormContext as useRHFormContext } from 'react-hook-form'

import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'
import { DateTime } from 'luxon'

import {
  RecordEditDto,
  RecordRenameModal,
} from '@tk/frontend/app/Records/common/crud'
import { getFormComponents } from '@tk/frontend/primitives'
import { DatePickerField } from '@tk/frontend/primitives/forms/DateTimePicker'
import { FieldInfo } from '@tk/frontend/primitives/forms/FieldInfo'
import { useFormContext } from '@tk/frontend/primitives/forms/FormConfigContext'

import { RECORD_STATUS_OPTIONS, RECORD_TYPE_OPTIONS } from './constants'
import { useFidGroupList } from './useFidGroupList'
import { useRecordReferenceLists } from './useRecordReferenceLists'

const Form = getFormComponents<RecordEditDto>()

export type RecordEditFieldsCoreProps = {
  mode: 'CREATE' | 'EDIT'
}

export function RecordEditFieldsCore({ mode }: RecordEditFieldsCoreProps) {
  const [lists] = RecordEditFieldsCore.useRecordReferenceLists()
  const [fidGroups] = RecordEditFieldsCore.useFidGroupList()

  const { editable } = useFormContext()
  const { watch } = useRHFormContext<RecordEditDto>()
  const validFromDate = watch('validFromDate')
  const validToDate = watch('validToDate')
  const modal = useModal(RecordRenameModal)

  return (
    <>
      <Form.Section title="Record">
        <Form.Field
          label="Name (Required)"
          name="name"
          disabled={mode === 'EDIT'}
          input={
            <Form.TextField
              rules={Form.rules.required}
              style={{ textTransform: 'uppercase' }}
            />
          }
          disabledSuffixElement={
            editable ? (
              <Button
                variant="contained"
                sx={{ marginLeft: '1rem' }}
                onClick={() => modal.show()}
              >
                Rename
              </Button>
            ) : undefined
          }
        />

        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />

        <Form.Field
          name="notes"
          label="Notes"
          input={<Form.TextField multiline={{ minRows: 2, maxRows: 8 }} />}
        />
      </Form.Section>

      <Form.Section title="Record Collection">
        <Form.Field
          label="Source (Required)"
          name="source.id"
          input={
            <Form.SelectField
              group
              rules={Form.rules.required}
              options={lists.source}
            />
          }
        />

        <Form.Field
          label="Record Type"
          name="type"
          info={
            <Form.FieldInfo
              details="This field is auto-calculated by PGS and cannot be changed"
              options={RECORD_TYPE_OPTIONS}
            />
          }
          input={<Form.SelectField options={RECORD_TYPE_OPTIONS} />}
          disabled
        />

        <Form.Field
          label="Collection Status (Required)"
          name="status"
          info={
            <Form.FieldInfo
              title="Controls whether we process and store data internally"
              options={RECORD_STATUS_OPTIONS}
            />
          }
          input={
            <Form.SelectField
              rules={Form.rules.required}
              options={RECORD_STATUS_OPTIONS}
            />
          }
        />

        <Form.Row>
          <Form.Field
            label="Valid From Date (UTC)"
            name="validFromDate"
            info={
              <FieldInfo
                title="Valid From and Valid To Dates:"
                details={
                  <p>
                    Valid to and valid from dates set the period which this
                    record is expected to have data for. Useful when linking
                    lineage to specify where each record sits in the history of
                    the instrument.
                  </p>
                }
              />
            }
            input={
              <DatePickerField
                name="validFromDate"
                maxDate={validToDate && DateTime.fromJSDate(validToDate)}
                rules={{
                  validate: (value) => {
                    const toDate =
                      validToDate && DateTime.fromJSDate(validToDate)
                    const fromDate = value && DateTime.fromJSDate(value)
                    if (toDate && fromDate && fromDate >= toDate) {
                      return 'Should be earlier than Valid To Date'
                    }
                    return true
                  },
                }}
              />
            }
          />

          <Form.Field
            label="Valid To Date (UTC)"
            name="validToDate"
            input={
              <DatePickerField
                name="validToDate"
                minDate={validFromDate && DateTime.fromJSDate(validFromDate)}
                rules={{
                  validate: (value) => {
                    const fromDate =
                      validFromDate && DateTime.fromJSDate(validFromDate)
                    const toDate = value && DateTime.fromJSDate(value)
                    if (fromDate && toDate && toDate <= fromDate) {
                      return 'Should be later than Valid From Date'
                    }
                    return true
                  },
                }}
              />
            }
          />
        </Form.Row>
      </Form.Section>

      <Form.Section title="General">
        <Form.Field
          name="fidGroup.id"
          label="Field Group"
          input={<Form.SelectField options={fidGroups} />}
        />
      </Form.Section>
    </>
  )
}

RecordEditFieldsCore.useRecordReferenceLists = useRecordReferenceLists
RecordEditFieldsCore.useFidGroupList = useFidGroupList
