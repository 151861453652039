import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { ReferenceDataRuleCreateButton } from './ReferenceDataRuleCreateButton'
import { ReferenceDataRuleCrudTable } from './ReferenceDataRuleCrudTable'

export function ReferenceDataRuleCrud() {
  return (
    <PageContentLayout
      title="Record Reference Data Entry Rules"
      controls={<ReferenceDataRuleCreateButton />}
      maxContentWidth={false}
    >
      <ReferenceDataRuleCrudTable />
    </PageContentLayout>
  )
}
