import {
  cloneElement,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import * as Icons from '@mui/icons-material'
import {
  Popover,
  PopoverProps,
  Stack,
  StackProps,
  Typography,
} from '@mui/material'

export type InfoPopoverProps = {
  children?: ReactNode
  content?: ReactNode
  contentContainer?: ReactElement<{ children: ReactNode }>
  iconSize?: 'small' | 'large' | 'medium' | 'inherit'
  colour?: 'light' | 'dark' | 'inherit'
  iconPosition?: 'prefix' | 'suffix'
  icon?: ReactNode
  stackProps?: StackProps
  disabled?: boolean
  anchorOrigin?: PopoverProps['anchorOrigin']
  transformOrigin?: PopoverProps['transformOrigin']
}

export function InfoPopover({
  children,
  content,
  contentContainer = (
    <Typography
      variant="inherit"
      margin="0.5rem"
      maxWidth="25rem"
      component="div"
    />
  ),
  colour = 'light',
  iconSize,
  iconPosition = 'suffix',
  icon = (
    <Icons.Info
      color="inherit"
      fontSize={iconSize}
      sx={{ paddingBottom: '2px' }}
    />
  ),
  stackProps,
  disabled = false,
  anchorOrigin = { horizontal: 'left', vertical: 'bottom' },
  transformOrigin,
}: Readonly<InfoPopoverProps>) {
  const ref = useRef<HTMLElement>(undefined)
  const [open, setOpen] = useState<false | EventTarget>(false)

  const isPopover = !!content

  let textColour = 'inherit'
  if (colour === 'light') {
    textColour = 'whitesmoke'
  }
  if (colour === 'dark') {
    textColour = 'GrayText'
  }

  // If the popover becomes disabled whilst it's open, close it
  useEffect(() => {
    if (disabled && open) {
      // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
      setOpen(false)
    }
  }, [disabled, open])

  const clickHandler = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!isPopover) {
        return
      }

      e.stopPropagation()
      if (!disabled) {
        setOpen(e.target)
      }
    },
    [disabled, isPopover]
  )

  return (
    <>
      {!!children && (
        <Stack
          direction="row"
          color={textColour}
          spacing={1}
          component="div"
          onClick={clickHandler}
          alignItems="center"
          style={{ cursor: isPopover && !disabled ? 'pointer' : 'auto' }}
          {...stackProps}
          ref={ref}
        >
          {isPopover && iconPosition === 'prefix' && !disabled && icon}

          {children}

          {isPopover && iconPosition === 'suffix' && !disabled && icon}
        </Stack>
      )}

      {!children && (
        <Stack
          color={textColour}
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="0.25rem"
          onClick={clickHandler}
          style={{ cursor: isPopover ? 'pointer' : 'auto' }}
          {...stackProps}
          ref={ref as any}
        >
          {icon}
        </Stack>
      )}

      {!!content && (
        <Popover
          open={!!open}
          anchorEl={ref.current}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          onClose={() => setOpen(false)}
        >
          {cloneElement(contentContainer, {
            children: content,
          })}
        </Popover>
      )}
    </>
  )
}
