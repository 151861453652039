import { ReactNode } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, BoxProps, Stack, Typography } from '@mui/material'

import {
  InfoPopover,
  InfoPopoverProps,
} from '@tk/frontend/primitives/InfoPopover'

interface PopoverCellProps {
  text?: ReactNode
  popoverText?: ReactNode
  disabled?: boolean
  maxWidth?: BoxProps['maxWidth']
  popoverProps?: Partial<InfoPopoverProps>
}

export function PopoverCell({
  text,
  popoverText,
  disabled,
  maxWidth = '40ch',
  popoverProps,
}: Readonly<PopoverCellProps>) {
  if (!text || (typeof text === 'string' && text.trim() === '')) {
    return null
  }

  return (
    <InfoPopover
      colour="inherit"
      contentContainer={<Stack direction="column" padding="0.5rem 0.75rem" />}
      iconPosition="suffix"
      icon={
        <Icons.Launch
          fontSize="inherit"
          color="primary"
          style={{ marginLeft: '0.15rem' }}
        />
      }
      content={
        <Box maxWidth={maxWidth} fontSize="13px">
          {popoverText ?? text}
        </Box>
      }
      stackProps={{
        sx: {
          'width': '100%',
          'justifyContent': 'space-between',
          '& > p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
      }}
      disabled={disabled}
      {...popoverProps}
    >
      <Typography variant="body2" component="div">
        {text}
      </Typography>
    </InfoPopover>
  )
}
