import { useMemo } from 'react'

import {
  GroupedDataField,
  groupedFields,
  ReferenceDataRuleFormData,
} from '@lib/pgs-types'
import { getFormComponents } from '@tk/frontend/primitives'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

const Form = getFormComponents<ReferenceDataRuleFormData>()

interface GroupedFieldEntryModeSelectorProps {
  field: GroupedDataField
  label: string
}

export function GroupedFieldEntryModeSelector({
  field,
  label,
}: Readonly<GroupedFieldEntryModeSelectorProps>) {
  const fields = useMemo(() => groupedFields[field], [field])

  const options = useMemo(
    () =>
      // Need the extra array element here to ensure we get a 0-indexed list to choose from
      ['0', ...fields].map<Option<number>>((field, index) => ({
        label: index.toString(),
        value: index,
      })),
    [fields]
  )

  return (
    <Form.Field
      name={field}
      label={label}
      input={
        <Form.SelectField
          hideNoneOption
          rules={Form.rules.required}
          options={options}
        />
      }
    />
  )
}
