import { Path } from 'react-hook-form'

import { ReferenceDataRuleRequestDto } from '@lib/pgs'
import { FieldEntryMode, ReferenceDataRuleFormData } from '@lib/pgs-types'
import { getFormComponents } from '@tk/frontend/primitives'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

interface FieldEntryModeSelectorProps {
  name: Path<ReferenceDataRuleRequestDto>
  label: string
}

const Form = getFormComponents<ReferenceDataRuleFormData>()

const options: Option<FieldEntryMode>[] = [
  { label: 'Required', value: 'REQUIRED' },
  { label: 'Optional', value: 'OPTIONAL' },
]

export function FieldEntryModeSelector({
  name,
  label,
}: Readonly<FieldEntryModeSelectorProps>) {
  return (
    <Form.Field
      name={name}
      label={label}
      input={<Form.SelectField hideNoneOption options={options} />}
    />
  )
}
