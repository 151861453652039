import { RecordColName } from './types'

export type TemplateNameType =
  | 'default'
  | 'hide_reference_data'
  | 'show_reference_data'

type TemplateColumnsType = Partial<Record<RecordColName, boolean>>

export const TEMPLATE_COLUMN_CONFIGS = {
  default: {
    'name': true,
    'source.name': true,
    'linkedRecords': true,
    'description': true,
    'status': true,
    'type': true,
    'scope': true,
    'fidGroup.name': true,
    'marketGroups': true,
    'assetType.name': true,
    'currency1.name': true,
    'dayCount1.name': true,
    'paymentFrequency1.name': true,
    'strikeUnit': true,
    'settlementIndex1.name': true,
    'settlementMethod': true,
    'period1.name': true,
    'period3.name': true,
    'resetFrequency1.name': true,
    'location': true,
    'company': true,
    'priceType': true,
    'clearingType': true,
    'pricingFrequency': true,
    'standardDiscounting': true,
    'subscription': true,
    'notes': true,
    'validFromDate': true,
    'modifiedBy': true,
    'createdBy': true,
  },
  hide_reference_data: {
    'name': true,
    'source.name': true,
    'linkedRecords': true,
    'description': true,
    'status': true,
    'type': true,
    'scope': true,
    'fidGroup.name': true,
    'marketGroups': true,
    'assetType.name': false,
    'currency1.name': false,
    'dayCount1.name': false,
    'paymentFrequency1.name': false,
    'strikeUnit': false,
    'settlementIndex1.name': false,
    'settlementMethod': false,
    'period1.name': false,
    'period3.name': false,
    'resetFrequency1.name': false,
    'location': false,
    'company': false,
    'priceType': false,
    'clearingType': false,
    'pricingFrequency': false,
    'standardDiscounting': false,
    'subscription': false,
    'notes': true,
    'validFromDate': true,
    'modifiedBy': true,
    'createdBy': true,
  },
  show_reference_data: {
    'name': true,
    'source.name': true,
    'linkedRecords': false,
    'description': true,
    'status': false,
    'type': false,
    'scope': false,
    'fidGroup.name': false,
    'marketGroups': false,
    'assetType.name': true,
    'currency1.name': true,
    'dayCount1.name': true,
    'paymentFrequency1.name': true,
    'strikeUnit': true,
    'settlementIndex1.name': true,
    'settlementMethod': true,
    'period1.name': true,
    'period3.name': true,
    'resetFrequency1.name': true,
    'location': true,
    'company': true,
    'priceType': true,
    'clearingType': true,
    'pricingFrequency': true,
    'standardDiscounting': true,
    'subscription': true,
    'notes': false,
    'validFromDate': false,
    'modifiedBy': true,
    'createdBy': true,
  },
} satisfies Record<TemplateNameType, TemplateColumnsType>
