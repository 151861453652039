import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useParams } from '@tanstack/react-router'

import { ReferenceDataRuleFormData } from '@lib/pgs-types'
import { useTanstackRouteEffect } from '@lib/web'
import { trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  useEntitlement,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { ReferenceDataRuleForm } from './ReferenceDataRuleForm'

const Form = getFormComponents<ReferenceDataRuleFormData>()

export function ReferenceDataRuleEditPage() {
  const { id } = useParams({
    from: '/reference-data/rule/edit/$id',
  })
  const [referenceDataRule] =
    trpc.reference.referenceDataRule.get.useSuspenseQuery(id)

  const editable = useEntitlement('reference-data-rules.manage')
  const form = Form.useForm({
    defaultValues: referenceDataRule,
    disabled: !editable,
  })

  useTanstackRouteEffect(() => {
    form.reset(referenceDataRule)
  })

  const notify = usePromiseNotification()
  const utils = useUtils()
  const updateMutation = trpc.reference.referenceDataRule.update.useMutation({
    onSuccess(data) {
      utils.reference.referenceDataRule.invalidate()

      form.reset(data)
    },
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    await notify(updateMutation.mutateAsync({ ...values, id }), {
      progressMessage: `Updating Reference Data Rule ${referenceDataRule.name}`,
      successMessage: `Updated Reference Data Rule ${values.name}`,
      failureMessage: `Failed to update Reference Data Rule`,
    })
  })

  return (
    <Form.Provider {...form}>
      <Form.FormConfigProvider editable={editable}>
        <Form.FormStack onSubmit={handleSubmit} submitOnCtrlEnter>
          <Form.SectionTitleRow title="Update Record Reference Data Entry Rule">
            <Button
              startIcon={<Icons.Save />}
              type="submit"
              variant="contained"
              disabled={updateMutation.isPending || !form.formState.isDirty}
            >
              Update
            </Button>
          </Form.SectionTitleRow>

          <ReferenceDataRuleForm />
        </Form.FormStack>
      </Form.FormConfigProvider>
    </Form.Provider>
  )
}
