import {
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import _ from 'lodash'

import { useColumnVisibilityModel } from '@tk/frontend/primitives/datagrid'
import { useQueryParam } from '@tk/frontend/primitives/useQueryParam'

import { TEMPLATE_COLUMN_CONFIGS, TemplateNameType } from './template-config'
import { RecordDto } from './types'

export function ColumnTemplateSelection() {
  const [template, setTemplate] = useQueryParam('template', {
    defaultValue: 'default',
  })

  const { model, setModel } = useColumnVisibilityModel<RecordDto>({
    id: false,
    parentChains: false,
    touchedByNames: false,
  })

  const handleChange = (event: SelectChangeEvent) => {
    setTemplate(event.target.value)
    const templateColumns =
      TEMPLATE_COLUMN_CONFIGS[event.target.value as TemplateNameType]
    const finalModel = { ...model, ...templateColumns }

    const filteredModel = _.pickBy(finalModel, (value) => value === false)

    if (_.isEmpty(filteredModel)) {
      setModel({})
    } else {
      setModel(filteredModel)
    }
  }

  return (
    <Stack
      direction="row"
      borderRadius="4px"
      alignItems="center"
      px={3}
      bgcolor={(theme) => theme.palette.grey[100]}
      color="white"
      padding={0}
    >
      <Select
        value={template}
        label="Select Template"
        onChange={handleChange}
        sx={{ width: '10rem', height: '28px' }}
        input={<OutlinedInput notched={false} />}
        size="small"
        variant="outlined"
      >
        <MenuItem value="default">All Columns</MenuItem>
        <MenuItem value="show_reference_data">
          Show Only Reference Data
        </MenuItem>
        <MenuItem value="hide_reference_data">Hide Reference Data</MenuItem>
      </Select>
    </Stack>
  )
}
