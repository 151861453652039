import { useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Add } from '@mui/icons-material'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'

import { ReferenceDataRuleFormData } from '@lib/pgs-types'
import { trpc } from '@tk/frontend/api'
import { getFormComponents } from '@tk/frontend/primitives/forms'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

import { FieldEntryModeSelector } from './FieldEntryModeSelector'
import { GroupedFieldEntryModeSelector } from './GroupedFieldEntryModeSelector'

const Form = getFormComponents<ReferenceDataRuleFormData>()

export function ReferenceDataRuleForm() {
  const form = useFormContext<ReferenceDataRuleFormData>()
  const { editable } = Form.useFormContext()
  const assetTypes = useFieldArray({
    control: form.control,
    name: 'assetTypes',
    keyName: 'formId',
  })
  const [assetTypeOptions] = trpc.reference.assetType.list.useSuspenseQuery(
    {},
    {
      select: (data) => {
        return data.map<Option<number>>(({ name, id, assetClass }) => ({
          label: name,
          value: id,
          group: assetClass.name,
        }))
      },
    }
  )

  const addAssetType = useCallback(() => {
    assetTypes.append({
      id: -1,
    })
  }, [assetTypes])

  return (
    <Form.SectionBodyGrid>
      <Form.Section title="Details" gridColumns={8}>
        <Form.Field
          name="name"
          label="Name (Required)"
          input={<Form.TextField rules={Form.rules.required} />}
        />

        <Form.Field name="description" label="Description" />
      </Form.Section>

      <Form.Section title="Individual Field Configuration" gridColumns={12}>
        <Form.Row>
          <FieldEntryModeSelector
            name="clearingTypeMode"
            label="Clearing Type"
          />
          <FieldEntryModeSelector name="companyMode" label="Company" />
          <FieldEntryModeSelector name="locationMode" label="Location" />
        </Form.Row>
        <Form.Row>
          <FieldEntryModeSelector
            name="pricingFrequencyMode"
            label="Pricing Frequency"
          />
          <FieldEntryModeSelector
            name="settlementMethodMode"
            label="Settlement Method"
          />
          <FieldEntryModeSelector
            name="standardDiscountingMode"
            label="Standard Discounting"
          />
        </Form.Row>
        <Form.Row>
          <FieldEntryModeSelector name="priceTypeMode" label="Price Type" />
          {/* Spacers */}
          <Box width="100%" />
          <Box width="100%" />
        </Form.Row>
      </Form.Section>

      <Form.Section title="Grouped Field Configuration" gridColumns={12}>
        <Form.Row>
          <GroupedFieldEntryModeSelector
            field="currencies"
            label="Number of Required Currencies"
          />
          <GroupedFieldEntryModeSelector
            field="day-counts"
            label="Number of Required Day Counts"
          />
          <GroupedFieldEntryModeSelector
            field="payment-frequencies"
            label="Number of Required Payment Frequencies"
          />
        </Form.Row>
        <Form.Row>
          <GroupedFieldEntryModeSelector
            field="periods"
            label="Number of Required Periods"
          />
          <GroupedFieldEntryModeSelector
            field="reset-frequencies"
            label="Number of Required Reset Frequencies"
          />
          <GroupedFieldEntryModeSelector
            field="settlement-indexes"
            label="Number of Required Settlement Indexes"
          />
        </Form.Row>
      </Form.Section>

      <Form.Section title="Related Asset Types" gridColumns={12}>
        <Table size="small">
          <TableBody>
            {assetTypes.fields.map(({ formId }, index) => (
              <TableRow key={formId}>
                <TableCell>
                  <Form.Field
                    name={`assetTypes.${index}.id`}
                    input={
                      <Form.SelectField
                        group
                        options={assetTypeOptions}
                        rules={Form.rules.required}
                      />
                    }
                  />
                </TableCell>
                <TableCell sx={{ width: '4rem' }}>
                  <Button
                    variant="text"
                    color="warning"
                    onClick={() => assetTypes.remove(index)}
                    disabled={!editable}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button startIcon={<Add />} onClick={addAssetType} disabled={!editable}>
          Add
        </Button>
      </Form.Section>
    </Form.SectionBodyGrid>
  )
}
