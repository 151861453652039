import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { TextField } from '@mui/material'

import { trpc } from '@tk/frontend/api'
import { useBloombergMappingTelemetry } from '@tk/frontend/app/Bloomberg/telemetry'
import { PackageActions } from '@tk/frontend/app/Bloomberg/types'
import { ButtonLink, useEntitlement } from '@tk/frontend/primitives'
import {
  ActionButtonGroup,
  useFilterQueryParams,
  usePaginationQueryParams,
  useSortModel,
} from '@tk/frontend/primitives/datagrid'
import {
  EditableDefaults,
  NamedBusinessObjectTable,
} from '@tk/frontend/primitives/datagrid/table'

import { ExtraCols } from './TickersCrud'
import { Dto } from './types'

export type TickersCrudTableProps = { filter: string | undefined }

export function TickersCrudTable() {
  const { trackEditSave, trackEditStart } =
    useBloombergMappingTelemetry('bloomberg/tickers')

  const mutator = trpc.bloomberg.ticker.upsert.useMutation({
    onMutate(variables) {
      trackEditSave({}, variables)
    },
  })

  const filter = useFilterQueryParams()
  const sort = useSortModel<'name'>('name', 'asc')
  const [pagination, onPaginationChange] = usePaginationQueryParams({
    resetPageFor: [sort.direction, sort.sort],
    initialPageSize: 100,
  })
  const listQuery = trpc.bloomberg.ticker.list.useQuery(
    {
      filter: filter.debouncedValue,
      sort: sort.sort,
      direction: sort.direction,
      page: pagination.page,
      size: pagination.pageSize,
    },
    {}
  )

  const list = listQuery.data?.content ?? []

  const extraCols = useMemo<ExtraCols>(() => {
    return [
      {
        type: 'string',
        field: 'objectId',
        headerName: 'Object ID',
        flex: 1,
        editable: true,
      },
    ]
  }, [])

  const packageActions = useMemo<PackageActions>(() => {
    return [
      {
        type: 'actions',
        field: 'nav-actions',
        flex: 1,
        align: 'left',
        getActions(params) {
          return [
            <ActionButtonGroup>
              <ButtonLink
                color="primary"
                startIcon={<Icons.TableRowsRounded />}
                variant="text"
                to="/bloomberg/records-mappings"
                search={{ 'initial-ticker-ids': params.row.id }}
              >
                Mappings
              </ButtonLink>
            </ActionButtonGroup>,
          ]
        },
      },
    ]
  }, [])

  const canManage = useEntitlement('bloomberg-mappings.manage')

  return (
    <>
      <TextField
        label="Search (Name)"
        placeholder="You may use the % wildcard to use partial search terms, for instance: AUD%"
        variant="filled"
        onChange={(e) => filter.setValue(e.target.value)}
        value={filter.value}
        fullWidth
      />
      <NamedBusinessObjectTable<Dto>
        list={list}
        onEditStart={trackEditStart}
        onEditSave={mutator.mutateAsync}
        extraColumns={extraCols}
        gridProps={{
          loading:
            listQuery.isFetching && listQuery.data?.page !== pagination.page,
          //
          //
          pageSizeOptions: [100],
          pagination: true,
          paginationMode: 'server',
          paginationModel: pagination,
          onPaginationModelChange: onPaginationChange,
          rowCount: listQuery.data?.totalElements ?? 0,
          //
          sortingMode: 'server',
          sortModel: sort.model,
          onSortModelChange: sort.setModel,
        }}
        editable={canManage && EditableDefaults}
        packageActions={packageActions}
      />
    </>
  )
}
