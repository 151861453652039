import { useCallback, useEffect } from 'react'

import {
  GridCallbackDetails,
  GridPaginationModel,
} from '@mui/x-data-grid-premium'

import { useQueryParam } from '@tk/frontend/primitives/useQueryParam'

export type UsePaginationQueryParamsOpts = {
  resetPageFor: (string | number | null | undefined)[]
  initialPageSize?: number
}

type OnGridPaginationModelChange = (
  model: GridPaginationModel,
  details?: GridCallbackDetails
) => void

export function usePaginationQueryParams({
  resetPageFor,
  initialPageSize,
}: UsePaginationQueryParamsOpts): [
  GridPaginationModel,
  OnGridPaginationModelChange
] {
  const [page, setPage] = useQueryParam('page', { defaultValue: 0 })
  const [size, setSize] = useQueryParam('size', {
    defaultValue: initialPageSize ?? 50,
  })

  // When parameters change which change the content of the pages, we also invalidate the current page
  useEffect(() => {
    setPage(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...resetPageFor, setPage])

  return [
    {
      page: page!,
      pageSize: Math.max(1, size!),
    },
    useCallback(
      (model, details) => {
        setPage(model.page)
        setSize(model.pageSize)
      },
      [setPage, setSize]
    ),
  ]
}
