import { FC, ReactNode, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import {
  Autocomplete,
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import _ from 'lodash'

import { AssetTagDto } from '@lib/pgs'
import { AssetTagType } from '@lib/pgs-types'
import { Inputs, trpc } from '@tk/frontend/api'
import {
  getFormComponents,
  InfoPopover,
  useFeature,
} from '@tk/frontend/primitives'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

import { ASSET_TYPE_TAG_OPTIONS } from './common'

type AssetTagHeaderProps = {
  children?: ReactNode
  info?: ReactNode
  width?: string | number
}

function AssetTagHeader({ children, info, width }: AssetTagHeaderProps) {
  return (
    <TableCell width={width}>
      <InfoPopover
        iconPosition="prefix"
        colour="dark"
        content={info ? <Form.FieldInfo details={info} /> : undefined}
      >
        <div>{children}</div>
      </InfoPopover>
    </TableCell>
  )
}

const Form = getFormComponents<Inputs['reference']['assetType']['update']>()

const AutoCompleteInput = ({
  name,
}: {
  name: `assetTags.${number}.synonyms`
}) => {
  const [inputValue, setInputValue] = useState('')

  const { field, fieldState } = Form.useController({
    name,
  })

  const errorMessage = fieldState.error?.message

  return (
    <Autocomplete
      {...field}
      multiple
      id={name}
      freeSolo
      options={[]}
      value={field.value as string[]}
      onChange={(_, newValue) => {
        field.onChange(newValue)
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        const options = newInputValue.split(',')

        if (options.length > 1) {
          field.onChange(
            (field.value as string[])
              .concat(options)
              .map((x) => x.trim())
              .filter((x) => x)
          )
          setInputValue('')
        } else {
          setInputValue(newInputValue)
        }
      }}
      getOptionLabel={(option) => option}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            error={!!errorMessage}
            label="Synonyms"
            placeholder="Separate each synonym with ,"
          />
        )
      }}
    />
  )
}

export const AssetTypeFormFields: FC = () => {
  const form = useFormContext()
  const referenceDataRulesEnabled = useFeature('recordDesignerReferenceData')
  const referenceDataRules = trpc.reference.referenceDataRule.list.useQuery(
    undefined,
    {
      enabled: referenceDataRulesEnabled,
      select: (data) =>
        data.map<Option<number>>(({ name, id }) => ({
          label: name,
          value: id,
        })),
    }
  )

  const assetTagsField = useFieldArray({
    control: form.control,
    name: 'assetTags',
    keyName: 'id',
  })

  const assetTagsByTypeQuery = trpc.reference.assetType.listTagsByType.useQuery(
    undefined,
    {
      select: (data) =>
        _.mapValues(data, (tags) =>
          tags.map((tag) => ({
            label: tag.name,
            value: tag.name,
          }))
        ),
    }
  )

  return (
    <Form.SectionBodyGrid>
      <Form.Section width="100%" title="Details">
        <Form.Field
          name="name"
          label="Name (Required)"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />

        <Form.Field name="description" label="Description" />

        {referenceDataRulesEnabled && (
          <Form.Field
            name="referenceDataRule.id"
            label="Record Entry Rule"
            input={<Form.SelectField options={referenceDataRules.data ?? []} />}
          />
        )}
      </Form.Section>

      <Form.Section title="Asset Tags" gridColumns={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <AssetTagHeader
                  width="20%"
                  info="The type of the asset tag, which could be product, instrument or variant"
                >
                  Type
                </AssetTagHeader>

                <AssetTagHeader
                  width="20%"
                  info="The asset tag name to be attached to the asset type."
                >
                  Name
                </AssetTagHeader>

                <AssetTagHeader
                  width="20%"
                  info="A more detailed description of this asset tag"
                >
                  Description
                </AssetTagHeader>

                <AssetTagHeader
                  width="20%"
                  info="Synonmyms for the asset tag that you're creating"
                >
                  Synonyms
                </AssetTagHeader>
              </TableRow>
            </TableHead>

            <TableBody>
              {assetTagsField.fields.map((assetTag, i) => {
                const tagType = form.watch(
                  `assetTags.${i}.assetTagType`
                ) as AssetTagType
                const tags = assetTagsByTypeQuery.data?.[tagType] ?? []

                return (
                  <TableRow key={assetTag.id}>
                    <TableCell>
                      <Form.Field
                        name={`assetTags.${i}.assetTagType`}
                        input={
                          <Form.SelectField
                            options={ASSET_TYPE_TAG_OPTIONS}
                            rules={Form.rules.required}
                          />
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <Form.Field
                        name={`assetTags.${i}.name`}
                        input={
                          <Form.SelectField
                            label="Name"
                            placeholder="Input the tag's name"
                            rules={Form.rules.required}
                            options={tags}
                            freeSolo
                          />
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <Form.Field
                        name={`assetTags.${i}.description`}
                        input={
                          <Form.TextField
                            label="Description"
                            placeholder="Input the tag's description"
                          />
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <Form.Field
                        name={`assetTags.${i}.synonyms`}
                        input={
                          <AutoCompleteInput name={`assetTags.${i}.synonyms`} />
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <ButtonGroup size="small" variant="text">
                        <Button
                          color="secondary"
                          onClick={() =>
                            assetTagsField.insert(i + 1, {
                              ...form.getValues(`assetTags.${i}`),
                            })
                          }
                        >
                          Clone
                        </Button>

                        <Button
                          color="warning"
                          data-testid={`assetTags.${i}.delete-button`}
                          onClick={() => assetTagsField.remove(i)}
                        >
                          Delete
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          onClick={() =>
            assetTagsField.append({
              name: '',
              description: '',
              synonyms: [],
            } as Partial<AssetTagDto>)
          }
        >
          Add
        </Button>
      </Form.Section>
    </Form.SectionBodyGrid>
  )
}
