import * as Icons from '@mui/icons-material'
import { useModal } from '@parameta/nice-modal-react'

import { When } from '@tk/frontend/primitives'
import { MenuButton, MenuItem } from '@tk/frontend/primitives/menu'

import { AssignmentModel } from './AssignmentModel'

export function CommercialPackagesMenu() {
  const modal = useModal(AssignmentModel)

  return (
    <When can="commercial-packages.manage">
      <MenuButton label="Commercial Packages" icon={<Icons.RequestQuote />}>
        <MenuItem
          startIcon={<Icons.Add />}
          onClick={() => {
            modal.show({
              type: 'add',
            })
          }}
        >
          Include in package
        </MenuItem>

        <MenuItem
          startIcon={<Icons.Remove />}
          onClick={() => {
            modal.show({
              type: 'remove',
            })
          }}
        >
          Exclude from package
        </MenuItem>
      </MenuButton>
    </When>
  )
}
