import { useCallback } from 'react'

import { useLocation, useNavigate } from '@tanstack/react-router'

type Opts<T, TEncoded = any> = {
  defaultValue?: T
  decode?: (value: TEncoded) => T
  encode?: (value: T | undefined) => TEncoded
}

// Overload for when defaultValue is provided
export function useQueryParam<T>(
  paramName: string,
  opts: Opts<T> & { defaultValue: T }
): readonly [T, (newValue: T | undefined) => void]

// Overload for when defaultValue is not provided
export function useQueryParam<T>(
  paramName: string,
  opts?: Opts<T>
): readonly [T | undefined, (newValue: T | undefined) => void]

export function useQueryParam<T>(paramName: string, opts?: Opts<T>) {
  const { decode, defaultValue, encode } = opts ?? {}
  /* 
    Ideally we'd use useSearch here, but there is an issue with the implementation in tanstack router which
    causes React to lose the cursor position in inputs. This same issue is not present within useLocation
    https://github.com/TanStack/router/issues/3162
  */
  const _value = useLocation({
    select: ({ search }) =>
      search?.[paramName as keyof typeof search] ?? defaultValue,
  }) as T | undefined
  const value = decode ? decode(_value) : _value
  const navigate = useNavigate()

  const setValue = useCallback(
    (newValue: T | undefined) => {
      navigate({
        to: '.',
        search: (oldParams: object | undefined) => {
          const encodedValue = encode ? encode(newValue) : newValue
          const newParams = { ...oldParams, [paramName]: encodedValue }

          if (encodedValue === undefined) {
            delete newParams[paramName]
          }

          return newParams
        },
        replace: true,
      })
    },
    [encode, navigate, paramName]
  )

  return [value, setValue] as const
}
