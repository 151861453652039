import { Box } from '@mui/material'

import { trpc, useUtils } from '@tk/frontend/api'
import { NamedBusinessObjectTableProps } from '@tk/frontend/primitives/datagrid/table'
import { PageContentLayout } from '@tk/frontend/primitives/layout'
import { useSimpleImportProvider } from '@tk/frontend/primitives/simple-import'

import { TickerCreateButton } from './TickerCreateButton'
import { TickersCrudTable } from './TickersCrudTable'
import { TickersImportExportMenu } from './TickersImportExportMenu'
import { Dto } from './types'

export type ExtraCols = Exclude<
  NamedBusinessObjectTableProps<Dto>['extraColumns'],
  undefined
>

export function TickersCrud() {
  const utils = useUtils()

  const simpleImportProvider = useSimpleImportProvider({
    route: trpc.bloomberg.ticker.import,
    utils: utils.bloomberg.ticker.import,
    previewUriTemplate: '/bloomberg/tickers/import/$taskId',
  })

  return (
    <PageContentLayout
      title="Bloomberg: Tickers"
      controls={
        <>
          <simpleImportProvider.Provider
            {...simpleImportProvider.providerProps}
          >
            <TickersImportExportMenu />
          </simpleImportProvider.Provider>
          <TickerCreateButton />
        </>
      }
    >
      <Box display="flex" flexDirection="column" height="100%" width="100%">
        <TickersCrudTable />
      </Box>
    </PageContentLayout>
  )
}
